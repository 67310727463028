import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore from "../../store/BusinessCardStore";
import { getUser } from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import { changeUserTypeData } from "../../global";

const ChangeType: React.FC = () => {
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const user = useStoreState(BusinessCardStore, getUser);

  const [response, setResponse] = useState("");

  const changeUserType = async (email: string, type: string) => {
    let data: changeUserTypeData = {
      email: email,
      type: type,
    };
    await axiosHelper.changeUserType(data).then((response) => {
      setResponse(response.msg);
    });
  };

  return (
    <>
      <Header user={user.username} page={"HOME"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="p-5 d-flex flex-column align-items-center justify-content-start mt-10">
          <div className=" d-flex flex-row align-items-center justify-content-center col-12 ">
            <div className="d-flex flex-row align-items-center justify-content-center col-5">
              Matteo
            </div>
            <div className="d-flex flex-row align-items-center justify-content-around col-5">
              <span
                className="d-flex flex-row align-items-center justify-content-center col-4 btn-black rounded "
                onClick={() => changeUserType("m.cecchetto00@gmail.com", "U")}
              >
                U
              </span>
              <span
                className="d-flex flex-row align-items-center justify-content-center col-4  btn-black rounded"
                onClick={() => changeUserType("m.cecchetto00@gmail.com", "G")}
              >
                G
              </span>
            </div>
          </div>
          <div className=" d-flex flex-row align-items-center justify-content-center col-12  mt-5">
            <div className="d-flex flex-row align-items-center justify-content-center col-5">
              Carlo
            </div>
            <div className="d-flex flex-row align-items-center justify-content-around col-5">
              <span
                className="d-flex flex-row align-items-center justify-content-center col-4 btn-black rounded "
                onClick={() => changeUserType("delucacarlo2001@gmail.com", "U")}
              >
                U
              </span>
              <span
                className="d-flex flex-row align-items-center justify-content-center col-4  btn-black rounded"
                onClick={() => changeUserType("delucacarlo2001@gmail.com", "G")}
              >
                G
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangeType;
